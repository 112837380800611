<template>
  <div>
    <a-modal v-model="visible" title="物料选择" width="750px" :footer="null" @cancel="onCloseModel">
      <a-row gutter="16">
        <a-col :span="24" style="width: 256px;">
          <a-input v-model="searchForm.search" placeholder="产品名称, 编码, 条码" allowClear @pressEnter="search" />
        </a-col>

        <a-col :span="24" style="max-width: 200px; margin-bottom: 12px;">
          <a-cascader :options="options" :load-data="loadData" placeholder="类目、属性" change-on-select
            @change="onChange" />
        </a-col>

        <a-space>
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-space>

        <a-space style="margin-left: 10px;">
          <a-button type="primary" icon="plus" @click="add_materials">增加物料</a-button>
        </a-space>
      </a-row>

      <div style="margin-top: 12px;">
        <a-table :data-source="items" rowKey="id" :columns="columns" :loading="loading" :pagination="pagination"
          @change="onChangeTable">
          <div slot="action" slot-scope="value, item, index">
            <a-button size="small" @click="select(item)">选择</a-button>
          </div>
        </a-table>
      </div>

    </a-modal>
  </div>
</template>

<script>
  import { inventoriemsOption } from '@/api/option';
  import { columns } from './columns';
  import { materialsClassificationSpecList } from '@/api/materials'
  import { materialsClassificationOption } from '@/api/option'
  
  export default {
    props: ['visible', 'warehouse'],
    model: { prop: 'visible', event: 'cancel' },
    data() {
      return {
        columns,
        pagination: {},
        searchForm: {
            search: '',
            category: '',
            supplier: undefined,
            page: 1,
            page_size: 16
            }, 
        classificationItems: [],
        options: [],
        loading: false,
        items: [],
      }
    },
    methods: {
      onCloseModel() {
        this.$emit('cancel', false);
      },
      onChangeTable(pagination, filters, sorter) {
        this.pagination = pagination;
        this.searchForm.page = pagination.current;
        this.searchForm.ordering = `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`;
        this.list();
      },
      search() {
        this.pagination.current = 1;
        this.searchForm.page = 1;
        this.list();
      },

      add_materials(){
        this.$router.push({ path: "/materials/information" });
      },
      list() {
        this.loading = true;
        inventoriemsOption(this.searchForm).then(data => {
          this.pagination.total = data.count;
          this.items = data.results;
        }).finally(() => {
          this.loading = false;
        });
      },
      select(item) {
        this.$emit('select', item);
        this.onCloseModel();
      },
      resetModel() {
        this.pagination = { current: 1, total: 0, pageSize: 16, showTotal: total => `共 ${total} 条` };
        this.searchForm = { page: 1, warehouse: this.warehouse, is_active: true };
        this.items = [];
      },

      onChange(value) {
      this.searchForm.category = value[0];
      this.searchForm.material_attributes__attribute = value[1];
      this.searchForm.material_attributes__value = value[2];
    },

    loadData(selectedOptions) {
      // console.log(selectedOptions)
      // console.log(selectedOptions[0].value)
      this.category_id = selectedOptions[0].value;
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      materialsClassificationSpecList({ category_id: this.category_id }).then((resp) => {
        targetOption.loading = false;
        targetOption.children = resp.result.map(item => ({
          value: item.attribute_id,
          label: item.attribute_name,
          children: item.value_list.map(value =>({
            value: value,
            label: value,
            isLeaf: true
          }))
        }));
        this.options = [...this.options];
      });
    },

    process_options() {
      this.options = this.classificationItems.map(item => ({
        value: item.id,
        label: item.name,
        isLeaf: false
      }));
    },

    initialize() {
      materialsClassificationOption({ page_size: 999999, is_active: true }).then((resp) => {
        this.classificationItems = resp.results;
        this.process_options();
      });

    },
  },

    watch: {
      visible(status) {
        if (status) {
          this.resetModel();
          this.list();
        }
      },
    },

    
    mounted() {
        this.initialize();
      },

  }
</script>